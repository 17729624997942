@import 'styles/variable';

.privilege {
  padding-bottom: 170px;
  position: relative;

  height: 1150px;
  &::before {
    content: '';
    width: 100%;
    height: 704px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $color-blue-dark;
  }
}

.bgStyles {
  padding-bottom: 0;
  height: auto;

  &.privilege::before {
    content: unset;
  }

  :global {
    .slider-stairs {
      .slick-track {
        height: 800px;

        .slick-slide:after {
          content: unset;
        }

        @media all and (max-width: 700px) {
          height: 490px;
        }
      }

      @media all and (max-width: 700px) {
        max-height: 490px;
      }
    }
  }
}


.title {
  position: relative;
  right: 80px;
  opacity: 0;
  transition: 1s;
}

.titleSmall {
  margin-top: 75px;
  margin-bottom: 32px;

  font-size: 40px;
  line-height: 51px;
  letter-spacing: -0.045em;
  text-transform: uppercase;

  @media all and (max-width: 700px) {
    font-size: 30px;
    letter-spacing: 0;
  }
}

.slider {
  position: relative;
  top: 80px;
  transition: 1s;
}

.animation {
  &.title {
    right: 0;
    opacity: 1;
  }
  &.slider {
    top: 0;
  }
}

@media all and (max-width: 940px) {
  .privilege {
    padding-bottom: 85px;
  }
}

@media all and (max-width: 700px) {
  .privilege {
    padding-bottom: 0;
    height: 100%;
    &::before {
      height: 61px;
      bottom: 0;
    }
  }
}

@media all and (max-height: 420px){
  .privilege{
    padding-bottom: 0;
    height: 100%;
    &::before {
      height: 600px;
    }
  }
}